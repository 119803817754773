// require("@rails/activestorage").start();

require("@rails/ujs").start();
require("turbolinks").start();
require("popper.js");

import "bootstrap/js/src/alert";
import Modal from "bootstrap/js/src/modal";
import bsCustomFileInput from "bs-custom-file-input";
import "css/site";

// Define Bootstrap Modal JQuery plugin manually since is not properly loaded
// TODO: why?
$.fn["modal"] = Modal._jQueryInterface;
$.fn["modal"].Constructor = Modal;

$(document).on("ready turbolinks:load", function () {
  bsCustomFileInput.init();
});

window.updateBonusRowListeners = function () {
  $(".bonus-row").click(function (e) {
    $("#editBonusModalContent").html(
      '<div class="modal-body"><div class="row align-items-center" style="min-height: 500px;"><div class="col text-center"><div class="spinner-grow" style="width: 3em; height: 3em;"></div></div></div></div>'
    );
    $.get($(this).data("url"));
  });
};

// console.log("trigger rebuild");
